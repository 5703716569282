.ion-text-small {
  font-size: 0.8em;
}

.ion-text-medium {
  font-size: 0.93em;
}

.ion-text-large {
  font-size: 1.5em;
}

.ion-text-capitalize-first {
  display: block;
  text-transform: lowercase;
}

.ion-text-capitalize-first::first-letter {
  text-transform: uppercase;
}

#homepage .homeBtn{
  box-shadow: 2px 4px 6px black;
  border-radius: 22px;
  cursor: pointer;
}
#homepage .homeBtn:hover{
  filter: brightness(1.1);
}

#homepage .btn {
  padding: 10px;
}


/*new css*/
#homepage {
  text-align: center;
}

#homepage .newbtn{
  padding: 10px;
}

#homepage .newbtn:hover{
  filter: brightness(1.1);
  cursor: pointer;
}

#homepage a{
  color: #1558cc;
  font-weight: 600;
  font-family: 'Varela Round', 'Ralewa', Arial, sans-serif;
  cursor: pointer;
}

#homepage .newbtn:hover a{
  color: var(--ion-color-primary, #3880ff);
}

#homepage a:hover{
  color: var(--ion-color-primary, #3880ff);
}

/* #homepage .social{
  position: absolute;
  bottom: 0;
  left: 0;
} */

/* #privacy {
  background: #fff !important;
  color: #000 !important;
} */
