.custom-file-upload {
  border: 0px;
  cursor: pointer;
  font-size: 85%;
}

::-webkit-validation-bubble-message {
  display: none;
}

.d_flex {
  display: flex;
  justify-content: space-between;
}

.text-xl p {
  font-size: large;
  color: black;
}
