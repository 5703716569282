$color-complete: #B8E986;
$color-progress: #9AB6D7;
$color-warning: #F5A623;
$color-inactive: #565656;

$icon-size: 50px;
$icon-border: 2px;

@mixin icon-color-style($color) {
  border-color: $color;
    ion-icon.secondary-icon{
        background: $color;
    }
}

.icon-stack {
    position: relative;
    display: flex;
  align-items: center;
  justify-content: center;
    width: $icon-size;
    height: $icon-size;
    //border: $icon-border solid $color-inactive;
    border-radius: 50%;
    margin: $icon-border auto;
    cursor: pointer;
    transition: opacity 0.5s;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
    
    ion-icon {
        flex: 1;
        
        &.primary-icon {
            margin: 0 auto;
            color: $color-inactive;
            z-index: 1;
        }
        &.secondary-icon {
            position: absolute;
            right: 0%;
            top: 20%;
            height: 32%;
            width: 32%;
            //background: $color-inactive;
            border-radius: 50%;
            padding: 2%;
            //border: $icon-border solid white;
            //color: white;
            z-index: 2;
        }
    }
    
    &.complete{
        @include icon-color-style($color-complete);
    }
    &.progress{
        @include icon-color-style($color-progress);
    }
    &.warning{
        @include icon-color-style($color-warning);
    }
    
    // Extra flavor for interactions
    &:hover{
        opacity: 0.60;
        //@include box-shadow-1(rgba(0,0,0,0.25)); // Remove this optional mixin
    }
}